import React from 'react'
import Layout from 'components/Layout'
import Content from 'components/Content'
import SEO from 'components/Seo'
import GridTemplate from 'components/GridTemplate'
import BlogList from 'components/BlogList'


const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title='Sayfa Bulunamadı' />
      <GridTemplate>
        <Content>
          <h1>
            Burada hiçbir şey yok...
            <span className='small' role='img' aria-label='Emoji assustado'>
              😱
            </span>
          </h1>

          <p className='support-title'>
          Belki de hiç olmadı!
            <span className='small' role='img' aria-label='Emoji feliz'>
              😄
            </span>
          </p>
          
        </Content>

        <BlogList />
      </GridTemplate>
    </Layout>
  )
}

export default NotFoundPage
